import * as React from "react"
import Layout from '../components/Layout/Layout';

// markup
const NotFoundPage = () => {
  return (
    <Layout pageTitle='Oopsy'>
      Oopsy doopsy. Can't find the page you're looking for.
      <a href="/">Go home</a>
    </Layout>
  )
}

export default NotFoundPage
